import React from 'react'
import FaqContent from '../components/faqContent'
import Seo from '../components/seo'

const AppFaq = () => {
  return (
    <>
      <Seo title="FAQs" />
      <section className="py-12 layout-space" id="app-faq">
        <FaqContent />
      </section>
    </>
  )
}
export default AppFaq
